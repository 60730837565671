<template>
  <div class="dialog-report">
    <v-card>
      <v-card-title>
        {{ $t(`admin.training.trainingList.table.button.addUser`) }}
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12">
            <v-autocomplete
              :label="$t('admin.training.trainingList.popup.tab.name')"
              :items="dataUser"
              multiple
              item-value="id"
              v-model="selectedUser"
              return-object
              :filter="filterObject"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="remove(data.item)"
                >
                  {{ data.item.firstName + ' ' + data.item.lastName }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.firstName + ' ' + data.item.lastName"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="actions">
        <v-btn data-test-id="cancel" color="primary" outlined @click="closeDialog('section')">
          {{ $t('globals.cancel') }}
        </v-btn>
        <v-btn
          data-test-id="add"
          color="primary"
          :disabled="!selectedUser.length"
          @click="saveData"
        >
          {{ inputDialogSection ? $t('globals.save') : $t('contentLibraries.add.add') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import VALIDATORS from '@/helpers/forms/validators.helper';
import {
  GET_USERS_LINKED_TO_TRAINING,
  LINK_USER_TO_TRAINING,
  TRAINING_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/trainings/trainings.actions';
import { mapActions } from 'vuex';

export default {
  name: 'DialogAdd',
  components: {},
  props: ['eventUsersId'],
  data: () => ({
    nameRules: VALIDATORS.REQUIRED.NAME,
    inputDialogSection: '',
    dataUser: [],
    selectedUser: [],
  }),
  watch: {
    eventUsersId(newValue) {
      this.dataUser = newValue;
    },
  },
  methods: {
    ...mapActions(TRAINING_ADMIN_MODULE, [LINK_USER_TO_TRAINING, GET_USERS_LINKED_TO_TRAINING]),
    async saveData() {
      const { eventId, idTraining } = this.$route.params;
      const userLinkTraining = this.selectedUser;
      await this[LINK_USER_TO_TRAINING]({ eventId, idTraining, userLinkTraining });
      await this[GET_USERS_LINKED_TO_TRAINING]({ eventId, idTraining });
      this.$emit('close-dialog');
    },
    closeDialog() {
      this.$emit('close-dialog');
    },
    remove(item) {
      const index = this.selectedUser.findIndex((e) => e.id === item.id);
      if (index >= 0) this.selectedUser.splice(index, 1);
    },
    filterObject(item, queryText) {
      const searchValues = `${item.firstName.toLowerCase()} ${item.lastName.toLowerCase()}`;
      return searchValues.includes(queryText.toLowerCase().trim());
    },
  },
  mounted() {
    this.dataUser = this.eventUsersId;
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout;

.training-list-table {
  .clickable {
    cursor: pointer;
  }

  .container-img {
    display: flex;

    &__img {
      width: 150px;
      height: 100%;
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  @include breakpoint(medium) {
    .container-img {
      width: 100px;
      height: 57px;
      border-radius: 10px 0 0 10px;
      border-right: 1px solid var(--v-gray-base);

      &__img {
        border-radius: 10px 0 0 10px;
      }
    }
  }
}
</style>
