<template>
  <div class="dialog-report">
    <v-card>
      <v-card-title class="font-weight-bold">
        {{ userName }}
      </v-card-title>

      <v-card-text>
        <div class="d-flex flex-column align-center mb-4">
          <p class="black--text font-weight-bold" style="align-self: start">Note</p>
          <v-card
            color="trainings-note d-flex flex-column"
            v-bind:style="{ backgroundColor: '#efefef' }"
            style="height: 240px; width: 100%; overflow-y: scroll"
          >
            <div v-if="currentUser">
              <div v-for="info in userNote" :key="info.id" class="mt-8 d-flex" :class="{}">
                <div class="d-flex align-center" style="width: 100%; padding-inline: 10px">
                  <div>{{ info.note }}</div>
                </div>
              </div>
            </div>
          </v-card>
        </div>
        <v-card-actions class="d-flex justify-end mt-8">
          <v-btn
            data-test-id="cancel"
            color="red"
            width="20%"
            outlined
            @click="$emit('close-dialog')"
          >
            {{ $t('globals.cancel') }}
          </v-btn>

          <v-btn width="20%" data-test-id="add" color="primary" @click="saveData">
            {{ $t('globals.save') }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  GET_ALL_TRAINING_NOTES,
  TRAINING_NOTE_MODULE,
} from '@/stores/umanize-admin/actions/training-note/trainings-note.actions';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DialogNote',
  components: {},
  props: {
    currentItem: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    userNote: [],
    userName: '',
    currentUser: false,
  }),
  computed: {
    ...mapGetters(TRAINING_NOTE_MODULE, ['allNoteState']),
  },
  methods: {
    ...mapActions(TRAINING_NOTE_MODULE, [GET_ALL_TRAINING_NOTES]),
    saveData() {
      this.$emit('close-dialog');
    },
  },
  async mounted() {
    this.userName = `${this.currentItem.profile?.firstName} ${this.currentItem?.profile?.lastName}`;
    if (this.currentItem?.userId === '11ed2add-7926-89e8-9bf8-0a27f213d720') {
      this.currentUser = true;
    }
    const { eventId, idTraining } = this.$route.params;
    await this[GET_ALL_TRAINING_NOTES]({ eventId, trainingId: idTraining });
    this.allNoteState.note.forEach((ele) => {
      this.userNote.push({ ...ele });
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout;

.training-list-table {
  .clickable {
    cursor: pointer;
  }

  .container-img {
    display: flex;

    &__img {
      width: 150px;
      height: 100%;
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  @include breakpoint(medium) {
    .container-img {
      width: 100px;
      height: 57px;
      border-radius: 10px 0 0 10px;
      border-right: 1px solid var(--v-gray-base);

      &__img {
        border-radius: 10px 0 0 10px;
      }
    }
  }
}
</style>
